<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-02 15:41:46
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-06 17:59:24
-->
<template>
  <div class="editDialog">
    <el-button :type="buttonType==1?'text':buttonType==2?'primary':''" @click="openModal()">发票申请信息</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="发票申请信息"
            :visible.sync="editDialogVisible"
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="贸易商名称" prop="name">
             <template>{{tradName}}</template>
        </el-form-item>
        <el-form-item label="名称" prop="invoiceName">
          <el-input v-model="ruleForm.invoiceName" placeholder="请输入开票抬头名称" ></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="IdentificationCode">
          <el-input v-model="ruleForm.IdentificationCode" placeholder="请输入纳税人识别号" ></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="ruleForm.address" placeholder="请输入地址" ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="photo">
          <el-input v-model="ruleForm.photo" placeholder="请输入电话" ></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="openingBank">
          <el-input v-model="ruleForm.openingBank" placeholder="请输入开户行" ></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="accountNumber">
          <el-input v-model="ruleForm.accountNumber" placeholder="请输入账号" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { EmptionRequest,InvoiceInfoRequests } from 'network/financial'

  export default {
    name: "InvoiceEdit",
    props: {
      roleId: {
        default() {
          return {}
        }
      },
      roleIds: {
        default() {
          return []
        }
      },
      tradName: {
        type: String,
        default(){
          return []
        }
      },
      buttonType : {
        type: Number,
        default (){
          return []
        }
      }
    },
    data() {
      var checkPhone = (rule, value, callback) => {
        let regPone = null
        let mobile = /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 最新16手机正则
        let tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/ // 座机
        if (value !== null && value.charAt(0) === '0') { // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
          regPone = tel
        } else if (value !== null && value.charAt(0) !== '0') {
          regPone = mobile
        }
        if (regPone === null) {
          return callback(
            new Error('请输入电话')
          )
        } else if (!regPone.test(value)) {
          return callback(
            new Error("请输入正确的电话格式,其中座机格式'区号-座机号码'")
          )
        } else {
          callback()
        }
      }
      return {
        editDialogVisible: false,
        ruleForm: {
          invoiceName: '',
          IdentificationCode: '',
          address: '',
          photo: '',
          openingBank: '',
          accountNumber: '',
        },
        rules: {
          invoiceName: [
            {required: true, message: '请输入开票抬头名称', trigger: 'blur'},
          ],
          IdentificationCode: [
            {required: true, message: '请输入纳税人识别号', trigger: 'blur'},
            // {pattern: '/^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/', message: '请输入正确的纳税人识别号'}
          ],
          address: [
            {required: true, message: '请输入地址', trigger: 'blur'},
          ],
          photo: [
            {required: true, message: '请输入电话', trigger: 'blur'},
            {validator: checkPhone,trigger: 'blur'}
            // {pattern: '/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/', message: '请输入正确的电话'}

          ],
          openingBank: [
            {required: true, message: '请输入开户行', trigger: 'blur'},
          ],
          accountNumber: [
            {required: true, message: '请输入账号', trigger: 'blur'},
            // {pattern: '/^\d{16,19}$/', message: '请输入正确的银行卡账号'}
          ],
        },
      }
    },
    methods: {

      openModal(){
       this.editDialogVisible=true
       InvoiceInfoRequests({
        id: this.roleId.emptionId,
        reconciliationId: this.roleId.reconciliationId
        }).then(res=>{
        let result = res.data
        this.ruleForm = {
          invoiceName: result.data.invoiceTitle,
          IdentificationCode: result.data.identificationNumber,
          address: result.data.invoiceAddress,
          photo: result.data.invoicePhone,
          openingBank: result.data.bankOfDeposit,
          accountNumber: result.data.accountNumber,
        }
       })
      },

      // 监听编辑角色的弹框的关闭事件
      editDialogClose() {
        this.$refs.ruleForm.resetFields();
      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        this.$refs.ruleForm.validate(valid => {
         if (valid) {
              EmptionRequest({
                reconciliationId: this.roleId.reconciliationId,
                invoiceTitle: this.ruleForm.invoiceName,
                identificationNumber: this.ruleForm.IdentificationCode,
                invoiceAddress: this.ruleForm.address,
                invoicePhone: this.ruleForm.photo,
                bankOfDeposit:this.ruleForm.openingBank,
                accountNumber: this.ruleForm.accountNumber
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('保存失败', 'error');
                }
                this.alertMessage('保存成功', 'success');

                // 隐藏弹窗
                this.editDialogVisible=false
                this.$refs.ruleForm.resetFields();
                // this.$emit('role-list');
              })
              
            } else {
              return false;
            }
        })
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
