<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-29 17:58:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-28 15:23:35
-->
<template>
  <el-button :type="buttonType==1?'text':buttonType==2?'primary':''"  @click="removeRoleById(roleId)">取消申请</el-button>
</template>

<script>
  import {CancelRequest} from 'network/financial'

  export default {
    name: "InvoiceCancel",
    props: {
      roleId: {
        type: Number,
        required: true
      },
      buttonType : {
        type: Number,
        default (){
          return []
        }
      }
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(id) {
        this.$confirm('此操作将取消该申请信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          CancelRequest({
            id: id,
          }).then(res => {
            let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage('取消失败', 'error');
            }
            this.alertMessage('已取消', 'success');
            this.$emit('role-list');
          });
        }).catch(() => {
          this.alertMessage('已取消删除','info');
        });
      }
    }
  }
</script>

<style scoped>

.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }

</style>
