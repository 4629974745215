<!--
 * @Description:
 * @Author:
 * @Date: 2023-03-01 14:51:56
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-05 11:38:50
-->
<template>
  <el-table highlight-current-row  :data="reconciliationList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column key="1" prop="reconciliationName" label="对账单标题" >
      <template slot-scope="scope">
        {{scope.row.reconciliationName}}({{scope.row.startDate.substring(0,10)}}~{{scope.row.endDate.substring(0,10)}})
      </template>
    </el-table-column>
    <el-table-column key="2" prop="emptionName" :label=" type==1?'购液企业名称':'贸易企业名称' " >
      <template slot-scope="scope">
        {{ type==1?scope.row.emptionName:scope.row.tardName }}
      </template>
    </el-table-column>
    <el-table-column key="3" label="创建时间" prop="reconciliationDate" width="170px" >
      <template slot-scope="scope">
        {{scope.row.reconciliationDate }}
      </template>
    </el-table-column>
    <el-table-column key="5" label="申请时间" prop="invoiceCreateDate" width="170px" v-if="radio==2&&type==2">
      <template slot-scope="scope">
        {{scope.row.invoiceCreateDate }}
      </template>
    </el-table-column>
    <el-table-column key="6" prop="countCar" label="对账车数(车)" ></el-table-column>
    <el-table-column key="7" prop="totalWeight" label="合计结算量(吨)" ></el-table-column>
    <el-table-column key="8" prop="totalAmount" label="合计结算金额(元)"  v-if="type==2"></el-table-column>
    <el-table-column key="9" prop="totalAmount" label="合计结算金额(元)"  v-if="type==1&&radio == 1"></el-table-column>
    

    <el-table-column key="10" prop="" label="状态" v-if="radio==2">
      <template slot-scope="scope">
        {{scope.row.invoiceStatus==0?'未申请':scope.row.invoiceStatus==1?'已申请，待开票':scope.row.invoiceStatus==2?'已开票':''}}
      </template>
    </el-table-column>
    <el-table-column key="11" prop="totalAmount" label="已开票金额(元)"  v-if="radio==2&&type==1"></el-table-column>
    <el-table-column key="12" prop="showInfoType" label="购液企业是否已查看详情"  v-if="radio==2&&type==1">
      <template slot-scope="scope">
        {{scope.row.showInfoType==1?'否':scope.row.showInfoType==2?'是':'--' }}
      </template>
    </el-table-column>
    <el-table-column key="13" label="开票时间" prop="InvoiceDate" width="170px" v-if="radio==2&&type==1">
      <template slot-scope="scope">
        {{scope.row.invoiceDate }}
      </template>
    </el-table-column>
    <el-table-column key="14" prop="residueQuantity" label="剩余未开票数量(吨)"></el-table-column>
    <el-table-column key="15" prop="residueMoney" label="未开票金额(元)" ></el-table-column>

    <el-table-column key="16" prop="invoicedAmount" label="已开票金额(元)" ></el-table-column>


    <el-table-column key="14" label="操作" width="200px">
      <template slot-scope="scope">
        <!-- <invoice-edit v-if="radio==1&&type==2" :role-id="scope.row.reconciliationId" :trad-name="scope.row.tradName" :buttonType="1"  @role-list="updateRoleList"/> -->
        <el-button type="text" @click="detailsPage(scope.row,scope.row.reconciliationId)">查看详情</el-button>
        <!-- <invoice-cancel v-if="radio==2&&scope.row.invoiceStatus==1&&type==2" :role-id="scope.row.reconciliationId" :trad-name="scope.row.tradName" :buttonType="1"  @role-list="updateRoleList"/> -->
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import InvoiceCancel from './InvoiceCancel.vue'
import InvoiceEdit from './InvoiceEdit'


  export default{
    name: "ReconciliationTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      },
      radio: {
        type: String,
        default(){
          return []
        }
      }
    },
    data() {
      return {
        type: localStorage.getItem('type')
      }
    },
    components: {
        InvoiceEdit,
        InvoiceCancel

    },
    methods: {

      detailsPage(scope,id,) {
        this.$router.replace({path:'/pages/biz/gas_factory/finance/bill/unapplied',query: {scope: scope,id: id,radio: this.radio}})
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('role-list');
      }
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }

</style>
